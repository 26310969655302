import { SurveyCampaignViewKeys } from "./keys";

export const englishValues = {
  [SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn]: "View report",
  [SurveyCampaignViewKeys.surveyCampaignView_emailTableHeader]: "Email",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkTableHeader]:
    "Survey Link",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkString]: "Link",
  [SurveyCampaignViewKeys.surveyCampaignView_progressTableHeader]: "Progress",
  [SurveyCampaignViewKeys.surveyCampaignView_progressPrompt]:
    "Number of questions that were answered so far.",
  [SurveyCampaignViewKeys.surveyCampaignView_firstNameTableHeader]:
    "First Name",
  [SurveyCampaignViewKeys.surveyCampaignView_lastNameTableHeader]: "Last Name",
  [SurveyCampaignViewKeys.surveyCampaignView_positionTableHeader]: "Position",
  [SurveyCampaignViewKeys.surveyCampaignView_teamTableHeader]: "Team",
  [SurveyCampaignViewKeys.surveyCampaignView_genderTableHeader]: "Gender",
  [SurveyCampaignViewKeys.surveyCampaignView_lastUpdatedTableHeader]:
    "Last updated",
  [SurveyCampaignViewKeys.surveyCampaignView_faqString]: "FAQ",
  [SurveyCampaignViewKeys.surveyCampaignView_availableStatusString]:
    "What are the available status for each surveys?",
  [SurveyCampaignViewKeys.surveyCampaignView_pendingStatusString]:
    "Email had yet to be sent out",
  [SurveyCampaignViewKeys.surveyCampaignView_sentStatusString]:
    "Email sent out successfully",
  [SurveyCampaignViewKeys.surveyCampaignView_failedStatusString]:
    "Email failed to be sent out",
  [SurveyCampaignViewKeys.surveyCampaignView_cancelledStatusString]:
    "Survey was cancelled",
  [SurveyCampaignViewKeys.surveyCampaignView_completedStatusString]:
    "Survey had been completed by employee",
  [SurveyCampaignViewKeys.surveyCampaignView_refreshPrompt]: "Refresh",
  [SurveyCampaignViewKeys.surveyCampaignView_refreshingDataPrompt]:
    "Refreshing data",
  [SurveyCampaignViewKeys.surveyCampaignView_resendEmailBtn]: "Resend email",
  [SurveyCampaignViewKeys.surveyCampaignView_cancelSurveyBtn]: "Cancel survey",
  [SurveyCampaignViewKeys.surveyCampaignView_reopenSurveyBtn]: "Re-open survey",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignBtn]:
    "Mark as completed",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt]:
    "Are you sure?",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignFurtherResponsesPrompt]:
    "Further survey reponses will not be accepted.",
  [SurveyCampaignViewKeys.surveyCampaignView_completingSurveyCampaignPrompt]:
    "Completing",
  [SurveyCampaignViewKeys.surveyCampaignView_completedSurveyCampaignPrompt]:
    "Completed",
  [SurveyCampaignViewKeys.surveyCampaignView_moreTooltip]: "More",
  [SurveyCampaignViewKeys.surveyCampaignView_deleteCampaignPrompt]:
    "This survey campaign will be deleted.",
  [SurveyCampaignViewKeys.surveyCampaignView_deletingSurveyCampaignPrompt]:
    "Deleting",
  [SurveyCampaignViewKeys.surveyCampaignView_deletedSurveyCampaignPrompt]:
    "Deleted",
  [SurveyCampaignViewKeys.surveyCampaignView_restoreCampaignPrompt]:
    "This survey campaign will be restored.",
  [SurveyCampaignViewKeys.surveyCampaignView_restoringSurveyCampaignPrompt]:
    "Restoring",
  [SurveyCampaignViewKeys.surveyCampaignView_restoredSurveyCampaignPrompt]:
    "Restored",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyString]: "Survey",
  [SurveyCampaignViewKeys.surveyCampaignView_createdString]: "Created",
};

export const malayValues = {
  [SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn]:
    "Tunjukkan laporan",
  [SurveyCampaignViewKeys.surveyCampaignView_emailTableHeader]: "E-mel",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkTableHeader]:
    "Pautan Tinjauan",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkString]: "Pautan",
  [SurveyCampaignViewKeys.surveyCampaignView_progressTableHeader]: "Progres",
  [SurveyCampaignViewKeys.surveyCampaignView_progressPrompt]:
    "Bilangan soalan yang telah dijawabkan setakat ini.",
  [SurveyCampaignViewKeys.surveyCampaignView_firstNameTableHeader]:
    "Nama Pertama",
  [SurveyCampaignViewKeys.surveyCampaignView_lastNameTableHeader]:
    "Nama Keluarga",
  [SurveyCampaignViewKeys.surveyCampaignView_positionTableHeader]: "Jawatan",
  [SurveyCampaignViewKeys.surveyCampaignView_teamTableHeader]: "Pasukan",
  [SurveyCampaignViewKeys.surveyCampaignView_genderTableHeader]: "Jantina",
  [SurveyCampaignViewKeys.surveyCampaignView_lastUpdatedTableHeader]:
    "Dikemas kini pada",
  [SurveyCampaignViewKeys.surveyCampaignView_faqString]: "Soalan lazim",
  [SurveyCampaignViewKeys.surveyCampaignView_availableStatusString]:
    "Apakah status yang tersedia untuk setiap tinjauan?",
  [SurveyCampaignViewKeys.surveyCampaignView_pendingStatusString]:
    "E-mel belum dihantar",
  [SurveyCampaignViewKeys.surveyCampaignView_sentStatusString]:
    "E-mel sudah dihantar",
  [SurveyCampaignViewKeys.surveyCampaignView_failedStatusString]:
    "E-mel tidak dapat dihantar",
  [SurveyCampaignViewKeys.surveyCampaignView_cancelledStatusString]:
    "Tinjauan telah dibatalkan",
  [SurveyCampaignViewKeys.surveyCampaignView_completedStatusString]:
    "Tinjauan telah dilengkapkan oleh pekerja",
  [SurveyCampaignViewKeys.surveyCampaignView_refreshPrompt]: "Muat semula",
  [SurveyCampaignViewKeys.surveyCampaignView_refreshingDataPrompt]:
    "Data dimuat semula",
  [SurveyCampaignViewKeys.surveyCampaignView_resendEmailBtn]:
    "Hantar e-mel semula",
  [SurveyCampaignViewKeys.surveyCampaignView_cancelSurveyBtn]:
    "Batalkan tinjauan",
  [SurveyCampaignViewKeys.surveyCampaignView_reopenSurveyBtn]:
    "Buka tinjauan semula",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignBtn]:
    "Tandai sebagai selesai",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt]:
    "Adakah anda pasti?",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignFurtherResponsesPrompt]:
    "Respons tinjauan yang lanjutnya tidak akan diterima lagi.",
  [SurveyCampaignViewKeys.surveyCampaignView_completingSurveyCampaignPrompt]:
    "Sedang ditamatkan",
  [SurveyCampaignViewKeys.surveyCampaignView_completedSurveyCampaignPrompt]:
    "Sudah ditamatkan",
  [SurveyCampaignViewKeys.surveyCampaignView_deleteCampaignPrompt]:
    "Tinjauan ini akan dipadamkan.",
  [SurveyCampaignViewKeys.surveyCampaignView_moreTooltip]: "Lebih",
  [SurveyCampaignViewKeys.surveyCampaignView_deletingSurveyCampaignPrompt]:
    "Sedang dipadamkan",
  [SurveyCampaignViewKeys.surveyCampaignView_deletedSurveyCampaignPrompt]:
    "Dipadamkan",
  [SurveyCampaignViewKeys.surveyCampaignView_restoreCampaignPrompt]:
    "Tinjauan ini akan dipulihkan",
  [SurveyCampaignViewKeys.surveyCampaignView_restoringSurveyCampaignPrompt]:
    "Sedang dipulihankan",
  [SurveyCampaignViewKeys.surveyCampaignView_restoredSurveyCampaignPrompt]:
    "Dipulihkan",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyString]: "Tinjauan",
  [SurveyCampaignViewKeys.surveyCampaignView_createdString]: "Dicipta pada",
};

export const chineseSimplifiedValues = {
  [SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn]: "查看报告",
  [SurveyCampaignViewKeys.surveyCampaignView_emailTableHeader]: "电子邮件",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkTableHeader]: "调查链接",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkString]: "链接",
  [SurveyCampaignViewKeys.surveyCampaignView_progressTableHeader]: "进度",
  [SurveyCampaignViewKeys.surveyCampaignView_progressPrompt]:
    "截至目前回答的问题数量。",
  [SurveyCampaignViewKeys.surveyCampaignView_firstNameTableHeader]: "名字",
  [SurveyCampaignViewKeys.surveyCampaignView_lastNameTableHeader]: "姓氏",
  [SurveyCampaignViewKeys.surveyCampaignView_positionTableHeader]: "职位",
  [SurveyCampaignViewKeys.surveyCampaignView_teamTableHeader]: "团队",
  [SurveyCampaignViewKeys.surveyCampaignView_genderTableHeader]: "性别",
  [SurveyCampaignViewKeys.surveyCampaignView_lastUpdatedTableHeader]:
    "最后更新于",
  [SurveyCampaignViewKeys.surveyCampaignView_faqString]: "常见问题",
  [SurveyCampaignViewKeys.surveyCampaignView_availableStatusString]:
    "每项调查的可用状态是什么？",
  [SurveyCampaignViewKeys.surveyCampaignView_pendingStatusString]: "邮件未发送",
  [SurveyCampaignViewKeys.surveyCampaignView_sentStatusString]: "邮件已发送",
  [SurveyCampaignViewKeys.surveyCampaignView_failedStatusString]:
    "邮件发送失败",
  [SurveyCampaignViewKeys.surveyCampaignView_cancelledStatusString]:
    "调查已取消",
  [SurveyCampaignViewKeys.surveyCampaignView_completedStatusString]:
    "员工已完成调查",
  [SurveyCampaignViewKeys.surveyCampaignView_refreshPrompt]: "刷新",
  [SurveyCampaignViewKeys.surveyCampaignView_refreshingDataPrompt]:
    "正在刷新数据",
  [SurveyCampaignViewKeys.surveyCampaignView_resendEmailBtn]: "重新发送邮件",
  [SurveyCampaignViewKeys.surveyCampaignView_cancelSurveyBtn]: "取消调查",
  [SurveyCampaignViewKeys.surveyCampaignView_reopenSurveyBtn]: "重新打开调查",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignBtn]: "标记为完成",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt]:
    "确定吗？",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignFurtherResponsesPrompt]:
    "进一步的调查回应将不再接受。",
  [SurveyCampaignViewKeys.surveyCampaignView_completingSurveyCampaignPrompt]:
    "正在完成",
  [SurveyCampaignViewKeys.surveyCampaignView_completedSurveyCampaignPrompt]:
    "已完成",
  [SurveyCampaignViewKeys.surveyCampaignView_deleteCampaignPrompt]:
    "此调查将被删除。",
  [SurveyCampaignViewKeys.surveyCampaignView_moreTooltip]: "更多",
  [SurveyCampaignViewKeys.surveyCampaignView_deletingSurveyCampaignPrompt]:
    "正在删除调查",
  [SurveyCampaignViewKeys.surveyCampaignView_deletedSurveyCampaignPrompt]:
    "已删除",
  [SurveyCampaignViewKeys.surveyCampaignView_restoreCampaignPrompt]:
    "此调查将被恢复",
  [SurveyCampaignViewKeys.surveyCampaignView_restoringSurveyCampaignPrompt]:
    "正在恢复调查",
  [SurveyCampaignViewKeys.surveyCampaignView_restoredSurveyCampaignPrompt]:
    "已恢复",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyString]: "调查",
  [SurveyCampaignViewKeys.surveyCampaignView_createdString]: "创建于",
};

export const chineseTraditionalValues = {
  [SurveyCampaignViewKeys.surveyCampaignView_viewReportBtn]: "顯示報告",
  [SurveyCampaignViewKeys.surveyCampaignView_emailTableHeader]: "電子郵件",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkTableHeader]: "調查鏈接",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyLinkString]: "鏈接",
  [SurveyCampaignViewKeys.surveyCampaignView_progressTableHeader]: "進度",
  [SurveyCampaignViewKeys.surveyCampaignView_progressPrompt]:
    "截至目前回答的問題數量。",
  [SurveyCampaignViewKeys.surveyCampaignView_firstNameTableHeader]: "名字",
  [SurveyCampaignViewKeys.surveyCampaignView_lastNameTableHeader]: "姓氏",
  [SurveyCampaignViewKeys.surveyCampaignView_positionTableHeader]: "職位",
  [SurveyCampaignViewKeys.surveyCampaignView_teamTableHeader]: "團隊",
  [SurveyCampaignViewKeys.surveyCampaignView_genderTableHeader]: "性別",
  [SurveyCampaignViewKeys.surveyCampaignView_lastUpdatedTableHeader]:
    "最後更新於",
  [SurveyCampaignViewKeys.surveyCampaignView_faqString]: "常見問題",
  [SurveyCampaignViewKeys.surveyCampaignView_availableStatusString]:
    "每項調查的可用狀態是什麼？",
  [SurveyCampaignViewKeys.surveyCampaignView_pendingStatusString]: "郵件未發送",
  [SurveyCampaignViewKeys.surveyCampaignView_sentStatusString]: "郵件已發送",
  [SurveyCampaignViewKeys.surveyCampaignView_failedStatusString]:
    "郵件發送失敗",
  [SurveyCampaignViewKeys.surveyCampaignView_cancelledStatusString]:
    "調查已取消",
  [SurveyCampaignViewKeys.surveyCampaignView_completedStatusString]:
    "員工已完成調查",
  [SurveyCampaignViewKeys.surveyCampaignView_refreshPrompt]: "重新整理",
  [SurveyCampaignViewKeys.surveyCampaignView_refreshingDataPrompt]:
    "正在重新整理資料",
  [SurveyCampaignViewKeys.surveyCampaignView_resendEmailBtn]: "重新發送郵件",
  [SurveyCampaignViewKeys.surveyCampaignView_cancelSurveyBtn]: "取消調查",
  [SurveyCampaignViewKeys.surveyCampaignView_reopenSurveyBtn]: "重新打開調查",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignBtn]: "標記為完成",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignAreYouSurePrompt]:
    "確定嗎？",
  [SurveyCampaignViewKeys.surveyCampaignView_completeCampaignFurtherResponsesPrompt]:
    "進一步的調查回應將不再接受。",
  [SurveyCampaignViewKeys.surveyCampaignView_completingSurveyCampaignPrompt]:
    "正在完成",
  [SurveyCampaignViewKeys.surveyCampaignView_completedSurveyCampaignPrompt]:
    "已完成",
  [SurveyCampaignViewKeys.surveyCampaignView_deleteCampaignPrompt]:
    "此調查將被刪除。",
  [SurveyCampaignViewKeys.surveyCampaignView_moreTooltip]: "更多",
  [SurveyCampaignViewKeys.surveyCampaignView_deletingSurveyCampaignPrompt]:
    "正在刪除調查",
  [SurveyCampaignViewKeys.surveyCampaignView_deletedSurveyCampaignPrompt]:
    "已刪除",
  [SurveyCampaignViewKeys.surveyCampaignView_restoreCampaignPrompt]:
    "此調查將被恢復",
  [SurveyCampaignViewKeys.surveyCampaignView_restoringSurveyCampaignPrompt]:
    "正在恢復調查",
  [SurveyCampaignViewKeys.surveyCampaignView_restoredSurveyCampaignPrompt]:
    "已恢復",
  [SurveyCampaignViewKeys.surveyCampaignView_surveyString]: "調查",
  [SurveyCampaignViewKeys.surveyCampaignView_createdString]: "創建於",
};
