import { PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Descriptions,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import UploadImage from "../../../components/UploadImage";
import config from "../../../config";
import {
  CreateCompanyRequest,
  GetCompaniesRequest,
  GetCompanyUsersByCompanyIdRequest,
  UpdateCompanyRequest,
  UploadCompanyLogoRequest,
} from "../../../constants/apiRequestResponse";
import { Company, CompanySizes, CompanyUser } from "../../../constants/types";
import {
  createCompany,
  getAllCompanies,
  getCompanyUsersByCompanyId,
  updateCompany,
  uploadCompanyLogo,
} from "../../../services/api";
import { getQueryParam, parseTime, setQueryParam } from "../../../util";

export default function CompanyTab() {
  enum DrawerModes {
    closed,
    add,
    edit,
  }

  const DISCLAIMER_MESSAGE = (
    <span>
      <p>This field is merely for ease of tracking purposes.</p>
      <p>
        Make sure to have included this subdomain in OSS bucket's list of custom
        domain name, or else it will not work.
      </p>
      <p>
        {" "}
        <a
          target="__blank"
          rel="no-referrer"
          href={config.alicloudOssDomainPanelUrl}
        >
          Click here
        </a>{" "}
        to verify.
      </p>
      <a
        target="__blank"
        rel="no-referrer"
        href="https://www.alibabacloud.com/help/en/object-storage-service/latest/map-custom-domain-names-1"
      >
        Click here
      </a>{" "}
      for official guide.
    </span>
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[] | undefined>();
  const [drawerMode, setDrawerMode] = useState<DrawerModes>(DrawerModes.closed);
  const [editCompanyId, setEditCompanyId] = useState("");
  const [companyUsersByCompanyId, setCompanyUsersByCompanyId] = useState<{
    [key: string]: CompanyUser[] | undefined;
  }>();

  const [isExpandedByCompanyId, setIsExpandedByCompanyId] = useState<{
    [key: string]: boolean;
  }>();
  const companyId = getQueryParam("companyId");

  /**
   * 22/6/2022 daniel.kwok
   * Hacky way to upload logo to work around antd's form
   */
  const [logo, setLogo] = useState<string | undefined>("");

  const isDrawerOpen = drawerMode !== DrawerModes.closed;
  const selectedCompany = companies?.find((c) => c._id === editCompanyId);

  const getUrlFromCompany = (companyOrSubdomain?: Company): string => {
    const subDomain = companyOrSubdomain?.subDomain;
    const url = window.location.origin;
    const subDomainWithProtocol = url.split(".")[0];
    const mainDomain = url.replace(subDomainWithProtocol, "");

    return `${window.location.protocol}//${subDomain}${mainDomain}`;
  };

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      render: (logo) => (
        <img src={logo} alt={logo} loading="lazy" height={50} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: (
        <span>
          Url&nbsp;
          <Tooltip title={DISCLAIMER_MESSAGE}>
            <QuestionCircleFilled />
          </Tooltip>
        </span>
      ),
      render: (company: Company) => (
        <a target="__blank" href={getUrlFromCompany(company)}>
          {getUrlFromCompany(company)}
        </a>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (time) => parseTime(time),
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      render: (time) => parseTime(time),
    },
    {
      title: "Action",
      render: (company: Company) => {
        return (
          <div>
            <Button
              type="link"
              onClick={() => {
                setEditCompanyId(company._id);
                setDrawerMode(DrawerModes.edit);
              }}
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getData();

    /**
     * 7/8/2022 daniel.kwok
     * If a company is selected in url,
     * expand & scroll to view
     *
     * Somehow the setTimeout is still needed else it wont work ¯\_(ツ)_/¯
     */
    setTimeout(() => {
      if (companyId) {
        const req: GetCompanyUsersByCompanyIdRequest = {
          companyId: companyId,
        };
        getCompanyUsersByCompanyId(req)
          .then((res) => {
            if (!res.success) throw new Error(res.message);
            setCompanyUsersByCompanyId((prev) => ({
              ...prev,
              [companyId]: res.companyUsers,
            }));
          })
          .catch((err) => {
            message.error(err.toString());
          })
          .finally(() => {
            setIsLoading(false);
          });

        setIsExpandedByCompanyId((prev) => ({
          ...prev,
          [companyId]: true,
        }));

        const element = document.querySelectorAll(
          `[data-row-key="${companyId}"]`
        )[0];
        element?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }, 0.5 * 1000);
  }, [companyId]);

  const getData = () => {
    setIsLoading(true);
    const req: GetCompaniesRequest = {};
    getAllCompanies(req)
      .then((res) => {
        if (!res.success) throw new Error(res.message);
        setCompanies(res.companies);
      })
      .catch((err) => {
        message.error(err.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            setDrawerMode(DrawerModes.add);
          }}
          icon={<PlusOutlined />}
        >
          Create
        </Button>
      </div>
      <Table
        loading={isLoading}
        rowKey={"_id"}
        columns={columns}
        dataSource={companies}
        scroll={{
          x: columns.length * 100,
        }}
        expandable={{
          expandedRowKeys:
            isExpandedByCompanyId &&
            Object.keys(isExpandedByCompanyId).filter(
              (key) => isExpandedByCompanyId[key]
            ),
          onExpand: (expanded: boolean, company: Company) => {
            setIsExpandedByCompanyId({
              ...isExpandedByCompanyId,
              [company._id]: expanded,
            });
            setQueryParam("companyId", company._id);

            if (!expanded) return null;

            const req: GetCompanyUsersByCompanyIdRequest = {
              companyId: company._id,
            };
            getCompanyUsersByCompanyId(req)
              .then((res) => {
                if (!res.success) throw new Error(res.message);
                setCompanyUsersByCompanyId({
                  ...companyUsersByCompanyId,
                  [company._id]: res.companyUsers,
                });
              })
              .catch((err) => {
                message.error(err.toString());
              })
              .finally(() => {
                setIsLoading(false);
              });
          },
          expandedRowRender: (company: Company) => {
            const companyUsers = companyUsersByCompanyId
              ? companyUsersByCompanyId[company._id]
              : [];

            const userColumns = [
              {
                title: "Name",
                dataIndex: "name",
                render: (name: string, companyUser: CompanyUser) => {
                  return (
                    <a href={`/companyUser?companyUserId=${companyUser._id}`}>
                      {name}
                    </a>
                  );
                },
              },
              {
                title: "Email",
                dataIndex: "email",
              },
              {
                title: "Mobile",
                dataIndex: "mobile",
              },
              {
                title: "Gender",
                dataIndex: "gender",
              },
            ];
            return (
              <div
                style={{
                  display: "flex",
                  width: window.innerWidth * 0.9,
                  gap: 10,
                }}
              >
                <Card style={{ flex: 4 }}>
                  <Typography.Title level={5}>Users</Typography.Title>
                  <Table
                    bordered={true}
                    size="small"
                    rowKey={"_id"}
                    columns={userColumns}
                    dataSource={companyUsers}
                  />
                </Card>
                <Card style={{ flex: 2 }}>
                  <Typography.Title level={5}>Company Info</Typography.Title>
                  <Descriptions
                    column={1}
                    layout="vertical"
                    labelStyle={{ fontWeight: 100 }}
                    size={"small"}
                  >
                    <Descriptions.Item label="ID">
                      {company._id}
                    </Descriptions.Item>
                    <Descriptions.Item label="Name">
                      {company.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Url">
                      {
                        <a
                          href={getUrlFromCompany(company)}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {getUrlFromCompany(company)}
                        </a>
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label="Email domains">
                      {company?.emailDomains?.map((domain) => {
                        return <Tag key={domain}>{domain}</Tag>;
                      })}
                    </Descriptions.Item>
                    <Descriptions.Item label="Is Admin">
                      {company?.isAdmin ? (
                        <Tag color="green">Yes</Tag>
                      ) : (
                        <Tag color="red">No</Tag>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Industry">
                      {company.industry}
                    </Descriptions.Item>
                    <Descriptions.Item label="Size">
                      {company.size}
                    </Descriptions.Item>
                    <Descriptions.Item label="Location">
                      {company.location}
                    </Descriptions.Item>
                    <Descriptions.Item label="Remarks">
                      {company.remark}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </div>
            );
          },
        }}
      />

      <Drawer
        title={
          drawerMode === DrawerModes.add ? "Add a new company" : `Edit company`
        }
        placement="right"
        visible={isDrawerOpen}
        onClose={() => {
          setDrawerMode(DrawerModes.closed);
          setEditCompanyId("");
        }}
        width={300}
        destroyOnClose={true}
      >
        <Form
          layout="vertical"
          onFinish={async (v: Company) => {
            v.logo = logo || selectedCompany?.logo || "";
            v.isAdmin = v.isAdmin ? true : false;

            if (drawerMode === DrawerModes.edit) {
              setIsEditLoading(true);
              const req2: UpdateCompanyRequest = {
                company: {
                  ...selectedCompany,
                  ...v,
                },
              };
              updateCompany(req2)
                .then((res2) => {
                  if (!res2.success) throw res2.message;

                  message.success(`Updated successfully`);

                  setDrawerMode(DrawerModes.closed);
                  getData();
                })

                .catch((err) => {
                  message.error(err.toString());
                })
                .finally(() => {
                  setIsEditLoading(false);
                  setEditCompanyId("");
                });
            } else {
              setIsAddLoading(true);

              const req2: CreateCompanyRequest = {
                company: v,
              };
              createCompany(req2)
                .then((res2) => {
                  if (!res2.success) throw res2.message;

                  message.success(`Created successfully`);

                  setDrawerMode(DrawerModes.closed);
                  getData();
                })

                .catch((err) => {
                  message.error(err.toString());
                })
                .finally(() => {
                  setIsAddLoading(false);
                  setEditCompanyId("");
                });
            }
          }}
          initialValues={{
            ...selectedCompany,
          }}
        >
          <Form.Item label="Logo" name="logo">
            <UploadImage
              onBase64Changed={async (base64: string) => {
                /**
                 * 23/6/2022 daniel.kwok
                 * Images work a little differently than other fields in the form
                 * Whenever user uploads something, it's actually uploaded to the server.
                 *
                 * i.e. from here on, code only deals with url, not base64
                 */

                try {
                  const req1: UploadCompanyLogoRequest = {
                    base64: base64,
                  };

                  const res1 = await uploadCompanyLogo(req1);
                  if (!res1.success) throw res1.message;

                  setLogo(res1.url);
                } catch (err) {
                  message.error(
                    typeof err === "string"
                      ? err
                      : typeof err === "object"
                      ? err?.toString()
                      : `Unable to upload image`
                  );
                }
              }}
              url={selectedCompany?.logo || logo}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Subdomain&nbsp;
                <Tooltip
                  title={`E.g. subdomain of ${window.location.hostname} is ${
                    window.location.hostname.split(".")[0]
                  }.`}
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </span>
            }
            help={DISCLAIMER_MESSAGE}
            name="subDomain"
            rules={[
              {
                required: true,
                message: "Subdomain is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <br />
          <Form.Item
            label={
              <span>
                Is Admin?&nbsp;
                <Tooltip
                  title={`If this company would have access to the admin panel.`}
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </span>
            }
            name="isAdmin"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={
              <span>
                Email domains&nbsp;
                <Tooltip title="E.g. gmail.com, acme.com, thedevelopmentcentre.com.">
                  <QuestionCircleFilled />
                </Tooltip>
              </span>
            }
            name="emailDomains"
            rules={[
              {
                required: true,
                message: "Email domains is required",
              },
            ]}
          >
            <Select mode="tags" style={{ width: "100%" }}></Select>
          </Form.Item>

          <Form.Item
            label={
              <span>
                Help desk email&nbsp;
                <Tooltip title="Email for users to reach out to incase something went wrong.">
                  <QuestionCircleFilled />
                </Tooltip>
              </span>
            }
            name="helpEmail"
            rules={[
              {
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Industry" name="industry">
            <Input />
          </Form.Item>
          <Form.Item label="Company Size" name="size">
            <Select allowClear showSearch placeholder={CompanySizes.medium}>
              {[
                CompanySizes.enterprise,
                CompanySizes.large,
                CompanySizes.medium,
                CompanySizes.small,
                CompanySizes.micro,
              ].map((size) => {
                return (
                  <Select.Option key={size} value={size}>
                    {size}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Form.Item>
              {drawerMode === DrawerModes.edit ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isEditLoading}
                >
                  Save
                </Button>
              ) : (
                <Button type="primary" htmlType="submit" loading={isAddLoading}>
                  Add
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
}
