import { ReportsPageKeys } from "./keys";

export const englishValues = {
  [ReportsPageKeys.reportsPage_reportsTitleString]: "Reports",
  [ReportsPageKeys.reportsPage_searchString]: "Search",
  [ReportsPageKeys.reportsPage_engagementSurveyPlaceholder]:
    "Engagement survey",
  [ReportsPageKeys.reportsPage_surveyString]: "Survey",
  [ReportsPageKeys.reportsPage_allDropdown]: "All",
  [ReportsPageKeys.reportsPage_searchPrompt]: "Search",
  [ReportsPageKeys.reportsPage_clearFiltersPrompt]: "Clear filters",
  [ReportsPageKeys.reportsPage_clearedPrompt]: "Cleared",
  [ReportsPageKeys.reportsPage_lastUpdateString]: "Last update: ",
  [ReportsPageKeys.reportsPage_viewReportBtn]: "View report",
  [ReportsPageKeys.reportsPage_endString]: "End",
};

export const malayValues = {
  [ReportsPageKeys.reportsPage_reportsTitleString]: "Laporan",
  [ReportsPageKeys.reportsPage_searchString]: "Cari",
  [ReportsPageKeys.reportsPage_engagementSurveyPlaceholder]:
    "Tinjauan penglibatan",
  [ReportsPageKeys.reportsPage_surveyString]: "Tinjauan",
  [ReportsPageKeys.reportsPage_allDropdown]: "Semua",
  [ReportsPageKeys.reportsPage_searchPrompt]: "Cari",
  [ReportsPageKeys.reportsPage_clearFiltersPrompt]: "Kosongkan penapis",
  [ReportsPageKeys.reportsPage_clearedPrompt]: "Dikosongkan",
  [ReportsPageKeys.reportsPage_lastUpdateString]: "Dikemas kini pada: ",
  [ReportsPageKeys.reportsPage_viewReportBtn]: "Tunjukkan laporan",
  [ReportsPageKeys.reportsPage_endString]: "Tamat",
};
export const chineseSimplifiedValues = {
  [ReportsPageKeys.reportsPage_reportsTitleString]: "报告",
  [ReportsPageKeys.reportsPage_searchString]: "搜索",
  [ReportsPageKeys.reportsPage_engagementSurveyPlaceholder]: "参与度调查",
  [ReportsPageKeys.reportsPage_surveyString]: "调查",
  [ReportsPageKeys.reportsPage_allDropdown]: "全部",
  [ReportsPageKeys.reportsPage_searchPrompt]: "搜索",
  [ReportsPageKeys.reportsPage_clearFiltersPrompt]: "清空筛选条件",
  [ReportsPageKeys.reportsPage_clearedPrompt]: "已清空",
  [ReportsPageKeys.reportsPage_lastUpdateString]: "最后更新时间：",
  [ReportsPageKeys.reportsPage_viewReportBtn]: "查看报告",
  [ReportsPageKeys.reportsPage_endString]: "结束",
};
export const chineseTraditionalValues = {
  [ReportsPageKeys.reportsPage_reportsTitleString]: "報告",
  [ReportsPageKeys.reportsPage_searchString]: "搜索",
  [ReportsPageKeys.reportsPage_engagementSurveyPlaceholder]: "參與度調查",
  [ReportsPageKeys.reportsPage_surveyString]: "調查",
  [ReportsPageKeys.reportsPage_allDropdown]: "全部",
  [ReportsPageKeys.reportsPage_searchPrompt]: "搜索",
  [ReportsPageKeys.reportsPage_clearFiltersPrompt]: "清空篩選條件",
  [ReportsPageKeys.reportsPage_clearedPrompt]: "已清空",
  [ReportsPageKeys.reportsPage_lastUpdateString]: "最後更新時間：",
  [ReportsPageKeys.reportsPage_viewReportBtn]: "查看報告",
  [ReportsPageKeys.reportsPage_endString]: "結束",
};
