import { LoadingOutlined } from "@ant-design/icons";
import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
const DashboardPage = lazy(() => import("../../pages/DashboardPage/index"));
const EditEmailTemplatePage = lazy(
  () => import("../../pages/EditEmailTemplatePage"),
);
const EditProfilePage = lazy(() => import("../../pages/EditProfilePage"));
const NotFoundPage = lazy(() => import("../../pages/NotFoundPage"));
const ReportsPage = lazy(() => import("../../pages/ReportsPage"));
const ReportsViewPage = lazy(() => import("../../pages/ReportViewPage"));
const ResetPasswordPage = lazy(
  () => import("../../pages/ResetPasswordPage/index"),
);
const SettingsPage = lazy(() => import("../../pages/SettingsPage"));
const SurveyCampaign360FormPage = lazy(
  () => import("../../pages/SurveyCampaign360FormPage"),
);
const SurveyCampaignFormPage = lazy(
  () => import("../../pages/SurveyCampaignFormPage"),
);
const SurveyCampaignsPage = lazy(
  () => import("../../pages/SurveyCampaignsPage/index"),
);

export default function CompanyUserRoutes() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <LoadingOutlined style={{ fontSize: 50 }} />
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={DashboardPage} />

          <Route
            exact
            path="/surveycampaign/create/:surveyTemplateName"
            component={SurveyCampaignFormPage}
          />

          <Route
            exact
            path="/surveycampaign/edit"
            component={SurveyCampaignFormPage}
          />

          <Route
            exact
            path="/surveycampaign/create/360/:surveyTemplateName"
            component={SurveyCampaign360FormPage}
          />

          <Route exact path="/surveycampaign" component={SurveyCampaignsPage} />

          <Route exact path="/report" component={ReportsPage} />

          <Route exact path="/report/view" component={ReportsViewPage} />

          <Route exact path="/profile" component={EditProfilePage} />

          <Route exact path="/settings" component={SettingsPage} />

          <Route
            exact
            path="/settings/emailtemplate"
            component={EditEmailTemplatePage}
          />

          <Route exact path="/reset-password" component={ResetPasswordPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
